export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `Date` scalar type represents a Date
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Date: any;
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: any;
  /** The `Decimal` scalar type represents a python Decimal. */
  Decimal: any;
  /**
   * Allows use of a JSON String for input / output from the GraphQL schema.
   *
   * Use of this type is *not recommended* as you lose the benefits of having a defined, static
   * schema (one of the key benefits of GraphQL).
   */
  JSONString: any;
  /**
   * The `Time` scalar type represents a Time value as
   * specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Time: any;
  /**
   * Leverages the internal Python implmeentation of UUID (uuid.UUID) to provide native UUID objects
   * in fields, resolvers and input.
   */
  UUID: any;
};

/** Добавление элемента в корзину. */
export type AddItemCartMutation = {
  __typename?: 'AddItemCartMutation';
  ok: Scalars['Boolean'];
  errors: Array<Maybe<Scalars['String']>>;
  /** Идентификатор */
  id?: Maybe<Scalars['ID']>;
};

/** Сценарии для рекламных банеров. */
export type AdvertisingBannerScriptType = Node & {
  __typename?: 'AdvertisingBannerScriptType';
  /** The ID of the object. */
  id: Scalars['ID'];
  version: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
  orgId: Scalars['Int'];
  title: Scalars['String'];
  /** Выберите терминалы */
  terminals: TerminalListTypeConnection;
  /** Выберите исключенные терминалы */
  excludedTerminals: TerminalListTypeConnection;
  slides?: Maybe<Array<Maybe<AdvertisingBannerSlideType>>>;
};


/** Сценарии для рекламных банеров. */
export type AdvertisingBannerScriptTypeTerminalsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  status?: Maybe<Scalars['String']>;
};


/** Сценарии для рекламных банеров. */
export type AdvertisingBannerScriptTypeExcludedTerminalsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  status?: Maybe<Scalars['String']>;
};

export type AdvertisingBannerScriptTypeConnection = {
  __typename?: 'AdvertisingBannerScriptTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AdvertisingBannerScriptTypeEdge>>;
};

/** A Relay edge containing a `AdvertisingBannerScriptType` and its cursor. */
export type AdvertisingBannerScriptTypeEdge = {
  __typename?: 'AdvertisingBannerScriptTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<AdvertisingBannerScriptType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** Слайды для рекламных банеров. */
export type AdvertisingBannerSlideType = Node & {
  __typename?: 'AdvertisingBannerSlideType';
  /** The ID of the object. */
  id: Scalars['ID'];
  version: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
  orgId: Scalars['Int'];
  /** Время показа слайда (в секундах) */
  displayTime: Scalars['Int'];
  img?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  /** Сценарий рекламного баннера */
  script?: Maybe<AdvertisingBannerScriptType>;
};

/** Применение программы лояльности к корзине. */
export type ApplyLoyaltyCartMutation = {
  __typename?: 'ApplyLoyaltyCartMutation';
  ok: Scalars['Boolean'];
  errors: Array<Maybe<Scalars['String']>>;
  /** Идентификатор */
  id?: Maybe<Scalars['ID']>;
};

/** Партии Товаров. */
export type BatchType = Node & {
  __typename?: 'BatchType';
  /** The ID of the object. */
  id: Scalars['ID'];
  version: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
  orgId: Scalars['Int'];
  product: ProductType;
  bestBefore?: Maybe<Scalars['Date']>;
  qrcode?: Maybe<Scalars['String']>;
  excluded: Scalars['Boolean'];
  shipmentbatchSet: ShipmentBatchTypeConnection;
  itemSet: ItemTypeConnection;
};


/** Партии Товаров. */
export type BatchTypeShipmentbatchSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
};


/** Партии Товаров. */
export type BatchTypeItemSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
};

export type BatchTypeConnection = {
  __typename?: 'BatchTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BatchTypeEdge>>;
};

/** A Relay edge containing a `BatchType` and its cursor. */
export type BatchTypeEdge = {
  __typename?: 'BatchTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<BatchType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type CartAddedSubscription = {
  __typename?: 'CartAddedSubscription';
  cart?: Maybe<CartType>;
};

/** An enumeration. */
export enum CartCheckManagerName {
  /** FakeCheckManager */
  Fakecheckmanager = 'FAKECHECKMANAGER',
  /** OFDCheckManager */
  Ofdcheckmanager = 'OFDCHECKMANAGER'
}

export type CartDeletedSubscription = {
  __typename?: 'CartDeletedSubscription';
  cart?: Maybe<CartType>;
};

export type CartPriceAddedSubscription = {
  __typename?: 'CartPriceAddedSubscription';
  cartPrice?: Maybe<CartPriceType>;
};

/** Цены для различных состояний корзин  */
export type CartPriceType = Node & {
  __typename?: 'CartPriceType';
  createdDt: Scalars['DateTime'];
  /** a5bdd4d8-c701-45b6-b95a-cc28419524a9 */
  uuid: Scalars['UUID'];
  price: Scalars['Float'];
  macAddress?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
};

export type CartPriceTypeConnection = {
  __typename?: 'CartPriceTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CartPriceTypeEdge>>;
};

/** A Relay edge containing a `CartPriceType` and its cursor. */
export type CartPriceTypeEdge = {
  __typename?: 'CartPriceTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<CartPriceType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum CartStatus {
  /** новая */
  New = 'NEW',
  /** ожидание платежа */
  WaitPayment = 'WAIT_PAYMENT',
  /** Оплачено */
  Paid = 'PAID',
  /** Ожидание чека */
  WaitReceipt = 'WAIT_RECEIPT',
  /** Повторное ожидание чека */
  ReReceiptWait = 'RE_RECEIPT_WAIT',
  /** Чек получен */
  ReceiptReceived = 'RECEIPT_RECEIVED',
  /** Закрыта */
  Closed = 'CLOSED',
  /** Закрыта с ошибкой получения чека */
  ErrorOfd = 'ERROR_OFD',
  /** Ожидание чека со статусом 2 или 3 заняло больше суток */
  ExpiredOfd = 'EXPIRED_OFD'
}

/** Корзины. */
export type CartType = Node & {
  __typename?: 'CartType';
  /** The ID of the object. */
  id: Scalars['ID'];
  version: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
  terminal: TerminalListType;
  /** Batch_id: count */
  items?: Maybe<Scalars['JSONString']>;
  itemsWithPrice?: Maybe<Scalars['JSONString']>;
  paid: Scalars['Boolean'];
  paidDt?: Maybe<Scalars['DateTime']>;
  /** ид транзакции оплаты */
  transactionId?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  receiptId?: Maybe<Scalars['String']>;
  customer?: Maybe<CustomerType>;
  /** Не отправлять, и не реагировать на изменения на фронте. Означает, что пользователь уже ушел и фронт  не обрабатывает эти события */
  notRelevant: Scalars['Boolean'];
  fromChatBot: Scalars['Boolean'];
  /** Эта ссылка кодируется в qr на стороне фронта и отображается кользователю, как чек */
  receiptUrl?: Maybe<Scalars['String']>;
  newOfdLogic: Scalars['Boolean'];
  /** Наименование класса провайдера ОФД */
  checkManagerName: CartCheckManagerName;
  /** Может быть 0.1.2.3.5.6 */
  statusCode?: Maybe<Scalars['Int']>;
  /** могут быть и штрих кодами акционных товаров */
  promoCodes?: Maybe<Array<Scalars['String']>>;
  status: CartStatus;
  draft: Scalars['Boolean'];
  stateUuid?: Maybe<Scalars['UUID']>;
  spendBonuses: Scalars['Boolean'];
  usedPoints: Scalars['Int'];
  fpd?: Maybe<Scalars['String']>;
  shiftNumber?: Maybe<Scalars['Int']>;
  receiptNumInShift?: Maybe<Scalars['Int']>;
  /** Корзина */
  terminallogSet: TerminalLogTypeConnection;
  /** Расчетная цена с учетом модификации */
  price?: Maybe<Scalars['Float']>;
  /** Цена корзины без учета модификации */
  stockPrice?: Maybe<Scalars['Float']>;
};


/** Корзины. */
export type CartTypeTerminallogSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
};

export type CartTypeConnection = {
  __typename?: 'CartTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CartTypeEdge>>;
};

/** A Relay edge containing a `CartType` and its cursor. */
export type CartTypeEdge = {
  __typename?: 'CartTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<CartType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type CartUpdatedSubscription = {
  __typename?: 'CartUpdatedSubscription';
  cart?: Maybe<CartType>;
};

/** Каталоги. */
export type CatalogType = Node & {
  __typename?: 'CatalogType';
  version: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
  orgId: Scalars['Int'];
  uuid: Scalars['UUID'];
  title: Scalars['String'];
  productSet: ProductTypeConnection;
  /** Можно выбрать каталог, вместо продукта. */
  pricemodificationSet: PriceModificationTypeConnection;
  /** The ID of the object. */
  id: Scalars['ID'];
};


/** Каталоги. */
export type CatalogTypeProductSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Каталоги. */
export type CatalogTypePricemodificationSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
};

export type CatalogTypeConnection = {
  __typename?: 'CatalogTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CatalogTypeEdge>>;
};

/** A Relay edge containing a `CatalogType` and its cursor. */
export type CatalogTypeEdge = {
  __typename?: 'CatalogTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<CatalogType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** Очистка корзины. */
export type CleanCartMutation = {
  __typename?: 'CleanCartMutation';
  ok: Scalars['Boolean'];
  errors: Array<Maybe<Scalars['String']>>;
  /** Идентификатор */
  id?: Maybe<Scalars['ID']>;
};

/** Создание корзины. */
export type CreateCartMutation = {
  __typename?: 'CreateCartMutation';
  ok: Scalars['Boolean'];
  errors: Array<Maybe<Scalars['String']>>;
  /** Идентификатор */
  id?: Maybe<Scalars['ID']>;
  /** Объект корзины */
  cart: CartType;
};

export type CreateCartPriceMutation = {
  __typename?: 'CreateCartPriceMutation';
  ok: Scalars['Boolean'];
  errors: Array<Maybe<Scalars['String']>>;
  /** Идентификатор */
  id?: Maybe<Scalars['ID']>;
};

/** Создание лога. */
export type CreateTerminalLogMutation = {
  __typename?: 'CreateTerminalLogMutation';
  ok: Scalars['Boolean'];
  errors: Array<Maybe<Scalars['String']>>;
  /** Идентификатор */
  id?: Maybe<Scalars['ID']>;
};

export type CustomerAddedSubscription = {
  __typename?: 'CustomerAddedSubscription';
  customer?: Maybe<CustomerType>;
};

export type CustomerDeletedSubscription = {
  __typename?: 'CustomerDeletedSubscription';
  customer?: Maybe<CustomerType>;
};

export type CustomerFeedbackAddedSubscription = {
  __typename?: 'CustomerFeedbackAddedSubscription';
  customerFeedback?: Maybe<CustomerFeedbackType>;
};

export type CustomerFeedbackDeletedSubscription = {
  __typename?: 'CustomerFeedbackDeletedSubscription';
  customerFeedback?: Maybe<CustomerFeedbackType>;
};

/** Типы Клиентских Отзывов */
export type CustomerFeedbackType = Node & {
  __typename?: 'CustomerFeedbackType';
  /** The ID of the object. */
  id: Scalars['ID'];
  version: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
  orgId: Scalars['Int'];
  customer?: Maybe<CustomerType>;
  data: Scalars['String'];
  feedbackType?: Maybe<Scalars['String']>;
};

export type CustomerFeedbackTypeConnection = {
  __typename?: 'CustomerFeedbackTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CustomerFeedbackTypeEdge>>;
};

/** A Relay edge containing a `CustomerFeedbackType` and its cursor. */
export type CustomerFeedbackTypeEdge = {
  __typename?: 'CustomerFeedbackTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<CustomerFeedbackType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type CustomerFeedbackUpdatedSubscription = {
  __typename?: 'CustomerFeedbackUpdatedSubscription';
  customerFeedback?: Maybe<CustomerFeedbackType>;
};

/** An enumeration. */
export enum CustomerFoodcardCategory {
  /** Автоматическое начисление карты питания 1 категории */
  FirstCategory = 'FIRST_CATEGORY',
  /** Автоматическое начисление карты питания 2 категории */
  SecondCategory = 'SECOND_CATEGORY',
  /** Ручное начисление карты питания */
  Manually = 'MANUALLY'
}

/** An enumeration. */
export enum CustomerLoyaltyStatus {
  /** Не участвует в программе лояльности */
  None = 'NONE',
  /** Друг Workeat/ВремяЕсть */
  Friend = 'FRIEND',
  /** VIP */
  Vip = 'VIP'
}

/** Данные покупателя в программе лояльности. */
export type CustomerLoyaltyType = {
  __typename?: 'CustomerLoyaltyType';
  /** ID покупателя */
  customerId?: Maybe<Scalars['ID']>;
  /** Количество бонусов */
  bonuses?: Maybe<Scalars['Int']>;
  /** Статус в программе лояльности */
  loyaltyStatus?: Maybe<Scalars['String']>;
  /** Процент начисления бонусов (Целое число) */
  bonusesPercent?: Maybe<Scalars['Int']>;
};

/** Типы Клиентов */
export type CustomerType = Node & {
  __typename?: 'CustomerType';
  /** The ID of the object. */
  id: Scalars['ID'];
  version: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
  username: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  emailConfirmed?: Maybe<Scalars['Boolean']>;
  phoneConfirmed?: Maybe<Scalars['Boolean']>;
  refrigBanned?: Maybe<Scalars['Boolean']>;
  bonuses: Scalars['Float'];
  loyaltyStatus: CustomerLoyaltyStatus;
  loyaltyCardId?: Maybe<Scalars['String']>;
  loyaltyCardCode?: Maybe<Scalars['String']>;
  foodcardCategory?: Maybe<CustomerFoodcardCategory>;
  bonusesAccrualAmount?: Maybe<Scalars['Int']>;
  dayOfBirth?: Maybe<Scalars['Date']>;
  sex?: Maybe<Scalars['String']>;
  customerfeedbackSet: CustomerFeedbackTypeConnection;
  /** Клиент */
  terminallogSet: TerminalLogTypeConnection;
  cartSet: CartTypeConnection;
};


/** Типы Клиентов */
export type CustomerTypeCustomerfeedbackSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
};


/** Типы Клиентов */
export type CustomerTypeTerminallogSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
};


/** Типы Клиентов */
export type CustomerTypeCartSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  fromChatBot?: Maybe<Scalars['Boolean']>;
};

export type CustomerTypeConnection = {
  __typename?: 'CustomerTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CustomerTypeEdge>>;
};

/** A Relay edge containing a `CustomerType` and its cursor. */
export type CustomerTypeEdge = {
  __typename?: 'CustomerTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<CustomerType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type CustomerUpdatedSubscription = {
  __typename?: 'CustomerUpdatedSubscription';
  customer?: Maybe<CustomerType>;
};



/** Деактуализация корзины. */
export type DeactualizeCartMutation = {
  __typename?: 'DeactualizeCartMutation';
  ok: Scalars['Boolean'];
  errors: Array<Maybe<Scalars['String']>>;
  /** Идентификатор */
  id?: Maybe<Scalars['ID']>;
};


/** Тип инвентаризации из терминала */
export enum InventoryActionTypes {
  ReturnToStock = 'ReturnToStock',
  ExpiredInTerminal = 'ExpiredInTerminal',
  Load = 'Load',
  Move = 'Move'
}

/** Сдача/приемка через корзину. */
export type InventoryCartMutation = {
  __typename?: 'InventoryCartMutation';
  ok: Scalars['Boolean'];
  errors: Array<Maybe<Scalars['String']>>;
  /** Идентификатор */
  id?: Maybe<Scalars['ID']>;
};

/** An enumeration. */
export enum ItemDefective {
  /** Поврежден на складе */
  A_1 = 'A_1',
  /** Просрочен на складе */
  A_2 = 'A_2',
  /** Утерян на складе */
  A_8 = 'A_8',
  /** Снято на склад */
  A_3 = 'A_3',
  /** Просрочен в терминале */
  A_4 = 'A_4',
  /** Утерян в терминале */
  A_5 = 'A_5',
  /** Поврежден в процессе доставки */
  A_6 = 'A_6',
  /** Утерян в процессе доставки */
  A_7 = 'A_7',
  /** Находится в неоплаченной корзине */
  A_9 = 'A_9',
  /** Снято на склад после доставки */
  A_10 = 'A_10',
  /** Виртуальный приход */
  A_11 = 'A_11'
}

/** Объектная модель единиц товаров, которые не могут быть проданы */
export type ItemType = Node & {
  __typename?: 'ItemType';
  /** The ID of the object. */
  id: Scalars['ID'];
  version: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
  orgId: Scalars['Int'];
  batch: BatchType;
  shipment?: Maybe<ShipmentType>;
  terminal?: Maybe<TerminalListType>;
  defective: ItemDefective;
  defectApproved?: Maybe<Scalars['Boolean']>;
  sentTo1c: Scalars['Boolean'];
  sentTo1cAt?: Maybe<Scalars['DateTime']>;
};

export type ItemTypeConnection = {
  __typename?: 'ItemTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ItemTypeEdge>>;
};

/** A Relay edge containing a `ItemType` and its cursor. */
export type ItemTypeEdge = {
  __typename?: 'ItemTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<ItemType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** Тип для работы с единицами товара в корзине. */
export type ItemsInputType = {
  /** ID товара */
  productId: Scalars['ID'];
  /** Количество товара */
  count?: Maybe<Scalars['Int']>;
  /** Цена без скидки */
  priceWithoutDiscount?: Maybe<Scalars['Float']>;
  /** Цена со скидкой */
  priceWithDiscount?: Maybe<Scalars['Float']>;
};


export type LeftoverAddedSubscription = {
  __typename?: 'LeftoverAddedSubscription';
  leftover?: Maybe<LeftoverType>;
};

export type LeftoverDeletedSubscription = {
  __typename?: 'LeftoverDeletedSubscription';
  leftover?: Maybe<LeftoverType>;
};

/** Остатки. */
export type LeftoverType = Node & {
  __typename?: 'LeftoverType';
  /** The ID of the object. */
  id: Scalars['ID'];
  version: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
  /** Ассортимент терминала */
  terminal: TerminalListType;
  product: ProductType;
  /** Текущее количество */
  currentAmount: Scalars['Int'];
  /** Место в терминале */
  position?: Maybe<Scalars['String']>;
  /** Товары в терминале по поставкам */
  batches?: Maybe<Scalars['JSONString']>;
  /** Значение модификации цены остатка, если равен 0, модификации нет */
  modificationValue?: Maybe<Scalars['Float']>;
  /** Стоимость товара с учетом модификации */
  price?: Maybe<Scalars['Float']>;
};

export type LeftoverTypeConnection = {
  __typename?: 'LeftoverTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<LeftoverTypeEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `LeftoverType` and its cursor. */
export type LeftoverTypeEdge = {
  __typename?: 'LeftoverTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<LeftoverType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type LeftoverUpdatedSubscription = {
  __typename?: 'LeftoverUpdatedSubscription';
  leftover?: Maybe<LeftoverType>;
};

/**
 * Мутации.
 *
 * Если название мутации содержит двойной прочерк в конце: `terminal_registration__`
 *
 * то эта мутация общего назначения и она не будет проверяться через механизмы авторизации терминалов.
 *
 *
 * При возникновении ошибок не обходимо извлечь code поле из ошибки и обрабатывать её в соответствии
 * с кодом. Доступные коды на данный момент:
 *
 * `SERVER_ERROR` - общая ошибка сервера
 *
 * `NOT_FOUND_ERROR` - ошибка получения данных
 *
 * `UNAUTHORIZED_ERROR` - ошибка авторизации
 *
 * `VALIDATION_ERROR` - ошибка валидации
 */
export type Mutations = {
  __typename?: 'Mutations';
  /** Авторизация терминала в системе. */
  terminalRegistration__?: Maybe<RegistrationTerminalMutation>;
  /** Создание лога. */
  terminalLogCreate?: Maybe<CreateTerminalLogMutation>;
  /** Создание корзины. */
  createCart?: Maybe<CreateCartMutation>;
  /** Добавление элемента в корзину. */
  addItemCart?: Maybe<AddItemCartMutation>;
  /** Удаление элемента из корзины. */
  removeItemCart?: Maybe<RemoveItemCartMutation>;
  /** Оплата корзины. */
  payCart?: Maybe<PayCartMutation>;
  /** Применение программы лояльности к корзине. */
  applyLoyaltyCart?: Maybe<ApplyLoyaltyCartMutation>;
  /** Сдача/приемка через корзину. */
  inventoryCart?: Maybe<InventoryCartMutation>;
  /** Деактуализация корзины. */
  deactualizeCart?: Maybe<DeactualizeCartMutation>;
  /** Очистка корзины. */
  cleanCart?: Maybe<CleanCartMutation>;
  /** Перевод корзины в статус ожидания оплаты. */
  setWaitingPaymentCartStatus?: Maybe<SetWaitingForPaymentStatusCartMutation>;
  /**
   * Получение данных клиента в программе лояльности.
   * :param customer: None, если клиент не найден либо не зарегистрирован в программе лояльности
   */
  updateCustomerLoyalty?: Maybe<UpdateCustomerLoyaltyMutation>;
  /** Получение данных оператора. */
  operatorAuth?: Maybe<OperatorAuthMutation>;
  cartPrice?: Maybe<CreateCartPriceMutation>;
};


/**
 * Мутации.
 *
 * Если название мутации содержит двойной прочерк в конце: `terminal_registration__`
 *
 * то эта мутация общего назначения и она не будет проверяться через механизмы авторизации терминалов.
 *
 *
 * При возникновении ошибок не обходимо извлечь code поле из ошибки и обрабатывать её в соответствии
 * с кодом. Доступные коды на данный момент:
 *
 * `SERVER_ERROR` - общая ошибка сервера
 *
 * `NOT_FOUND_ERROR` - ошибка получения данных
 *
 * `UNAUTHORIZED_ERROR` - ошибка авторизации
 *
 * `VALIDATION_ERROR` - ошибка валидации
 */
export type MutationsTerminalRegistration__Args = {
  authToken: Scalars['UUID'];
};


/**
 * Мутации.
 *
 * Если название мутации содержит двойной прочерк в конце: `terminal_registration__`
 *
 * то эта мутация общего назначения и она не будет проверяться через механизмы авторизации терминалов.
 *
 *
 * При возникновении ошибок не обходимо извлечь code поле из ошибки и обрабатывать её в соответствии
 * с кодом. Доступные коды на данный момент:
 *
 * `SERVER_ERROR` - общая ошибка сервера
 *
 * `NOT_FOUND_ERROR` - ошибка получения данных
 *
 * `UNAUTHORIZED_ERROR` - ошибка авторизации
 *
 * `VALIDATION_ERROR` - ошибка валидации
 */
export type MutationsTerminalLogCreateArgs = {
  logType: TerminalLogTypes;
  message: Scalars['JSONString'];
  orgId?: Maybe<Scalars['Int']>;
  paymentProvider?: Maybe<PaymentTypes>;
};


/**
 * Мутации.
 *
 * Если название мутации содержит двойной прочерк в конце: `terminal_registration__`
 *
 * то эта мутация общего назначения и она не будет проверяться через механизмы авторизации терминалов.
 *
 *
 * При возникновении ошибок не обходимо извлечь code поле из ошибки и обрабатывать её в соответствии
 * с кодом. Доступные коды на данный момент:
 *
 * `SERVER_ERROR` - общая ошибка сервера
 *
 * `NOT_FOUND_ERROR` - ошибка получения данных
 *
 * `UNAUTHORIZED_ERROR` - ошибка авторизации
 *
 * `VALIDATION_ERROR` - ошибка валидации
 */
export type MutationsAddItemCartArgs = {
  cartData: ItemsInputType;
  cartId: Scalars['ID'];
  stateUuid: Scalars['UUID'];
};


/**
 * Мутации.
 *
 * Если название мутации содержит двойной прочерк в конце: `terminal_registration__`
 *
 * то эта мутация общего назначения и она не будет проверяться через механизмы авторизации терминалов.
 *
 *
 * При возникновении ошибок не обходимо извлечь code поле из ошибки и обрабатывать её в соответствии
 * с кодом. Доступные коды на данный момент:
 *
 * `SERVER_ERROR` - общая ошибка сервера
 *
 * `NOT_FOUND_ERROR` - ошибка получения данных
 *
 * `UNAUTHORIZED_ERROR` - ошибка авторизации
 *
 * `VALIDATION_ERROR` - ошибка валидации
 */
export type MutationsRemoveItemCartArgs = {
  cartData: ItemsInputType;
  cartId: Scalars['ID'];
  stateUuid: Scalars['UUID'];
};


/**
 * Мутации.
 *
 * Если название мутации содержит двойной прочерк в конце: `terminal_registration__`
 *
 * то эта мутация общего назначения и она не будет проверяться через механизмы авторизации терминалов.
 *
 *
 * При возникновении ошибок не обходимо извлечь code поле из ошибки и обрабатывать её в соответствии
 * с кодом. Доступные коды на данный момент:
 *
 * `SERVER_ERROR` - общая ошибка сервера
 *
 * `NOT_FOUND_ERROR` - ошибка получения данных
 *
 * `UNAUTHORIZED_ERROR` - ошибка авторизации
 *
 * `VALIDATION_ERROR` - ошибка валидации
 */
export type MutationsPayCartArgs = {
  cartId: Scalars['ID'];
  paymentProvider?: Maybe<PaymentTypes>;
  transactionNumber?: Maybe<Scalars['String']>;
};


/**
 * Мутации.
 *
 * Если название мутации содержит двойной прочерк в конце: `terminal_registration__`
 *
 * то эта мутация общего назначения и она не будет проверяться через механизмы авторизации терминалов.
 *
 *
 * При возникновении ошибок не обходимо извлечь code поле из ошибки и обрабатывать её в соответствии
 * с кодом. Доступные коды на данный момент:
 *
 * `SERVER_ERROR` - общая ошибка сервера
 *
 * `NOT_FOUND_ERROR` - ошибка получения данных
 *
 * `UNAUTHORIZED_ERROR` - ошибка авторизации
 *
 * `VALIDATION_ERROR` - ошибка валидации
 */
export type MutationsApplyLoyaltyCartArgs = {
  cartId: Scalars['ID'];
  customerId?: Maybe<Scalars['ID']>;
  spendBonuses?: Maybe<Scalars['Boolean']>;
  stateUuid?: Maybe<Scalars['UUID']>;
  usedPoints?: Maybe<Scalars['Int']>;
};


/**
 * Мутации.
 *
 * Если название мутации содержит двойной прочерк в конце: `terminal_registration__`
 *
 * то эта мутация общего назначения и она не будет проверяться через механизмы авторизации терминалов.
 *
 *
 * При возникновении ошибок не обходимо извлечь code поле из ошибки и обрабатывать её в соответствии
 * с кодом. Доступные коды на данный момент:
 *
 * `SERVER_ERROR` - общая ошибка сервера
 *
 * `NOT_FOUND_ERROR` - ошибка получения данных
 *
 * `UNAUTHORIZED_ERROR` - ошибка авторизации
 *
 * `VALIDATION_ERROR` - ошибка валидации
 */
export type MutationsInventoryCartArgs = {
  action: InventoryActionTypes;
  cartId: Scalars['ID'];
  operatorId: Scalars['ID'];
  salesPointId?: Maybe<Scalars['ID']>;
};


/**
 * Мутации.
 *
 * Если название мутации содержит двойной прочерк в конце: `terminal_registration__`
 *
 * то эта мутация общего назначения и она не будет проверяться через механизмы авторизации терминалов.
 *
 *
 * При возникновении ошибок не обходимо извлечь code поле из ошибки и обрабатывать её в соответствии
 * с кодом. Доступные коды на данный момент:
 *
 * `SERVER_ERROR` - общая ошибка сервера
 *
 * `NOT_FOUND_ERROR` - ошибка получения данных
 *
 * `UNAUTHORIZED_ERROR` - ошибка авторизации
 *
 * `VALIDATION_ERROR` - ошибка валидации
 */
export type MutationsDeactualizeCartArgs = {
  cartId: Scalars['ID'];
};


/**
 * Мутации.
 *
 * Если название мутации содержит двойной прочерк в конце: `terminal_registration__`
 *
 * то эта мутация общего назначения и она не будет проверяться через механизмы авторизации терминалов.
 *
 *
 * При возникновении ошибок не обходимо извлечь code поле из ошибки и обрабатывать её в соответствии
 * с кодом. Доступные коды на данный момент:
 *
 * `SERVER_ERROR` - общая ошибка сервера
 *
 * `NOT_FOUND_ERROR` - ошибка получения данных
 *
 * `UNAUTHORIZED_ERROR` - ошибка авторизации
 *
 * `VALIDATION_ERROR` - ошибка валидации
 */
export type MutationsCleanCartArgs = {
  cartId: Scalars['ID'];
  stateUuid: Scalars['UUID'];
};


/**
 * Мутации.
 *
 * Если название мутации содержит двойной прочерк в конце: `terminal_registration__`
 *
 * то эта мутация общего назначения и она не будет проверяться через механизмы авторизации терминалов.
 *
 *
 * При возникновении ошибок не обходимо извлечь code поле из ошибки и обрабатывать её в соответствии
 * с кодом. Доступные коды на данный момент:
 *
 * `SERVER_ERROR` - общая ошибка сервера
 *
 * `NOT_FOUND_ERROR` - ошибка получения данных
 *
 * `UNAUTHORIZED_ERROR` - ошибка авторизации
 *
 * `VALIDATION_ERROR` - ошибка валидации
 */
export type MutationsSetWaitingPaymentCartStatusArgs = {
  cartId: Scalars['ID'];
  items: Array<Maybe<ItemsInputType>>;
  promoCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/**
 * Мутации.
 *
 * Если название мутации содержит двойной прочерк в конце: `terminal_registration__`
 *
 * то эта мутация общего назначения и она не будет проверяться через механизмы авторизации терминалов.
 *
 *
 * При возникновении ошибок не обходимо извлечь code поле из ошибки и обрабатывать её в соответствии
 * с кодом. Доступные коды на данный момент:
 *
 * `SERVER_ERROR` - общая ошибка сервера
 *
 * `NOT_FOUND_ERROR` - ошибка получения данных
 *
 * `UNAUTHORIZED_ERROR` - ошибка авторизации
 *
 * `VALIDATION_ERROR` - ошибка валидации
 */
export type MutationsUpdateCustomerLoyaltyArgs = {
  cardCode: Scalars['String'];
};


/**
 * Мутации.
 *
 * Если название мутации содержит двойной прочерк в конце: `terminal_registration__`
 *
 * то эта мутация общего назначения и она не будет проверяться через механизмы авторизации терминалов.
 *
 *
 * При возникновении ошибок не обходимо извлечь code поле из ошибки и обрабатывать её в соответствии
 * с кодом. Доступные коды на данный момент:
 *
 * `SERVER_ERROR` - общая ошибка сервера
 *
 * `NOT_FOUND_ERROR` - ошибка получения данных
 *
 * `UNAUTHORIZED_ERROR` - ошибка авторизации
 *
 * `VALIDATION_ERROR` - ошибка валидации
 */
export type MutationsOperatorAuthArgs = {
  personalCode: Scalars['String'];
};


/**
 * Мутации.
 *
 * Если название мутации содержит двойной прочерк в конце: `terminal_registration__`
 *
 * то эта мутация общего назначения и она не будет проверяться через механизмы авторизации терминалов.
 *
 *
 * При возникновении ошибок не обходимо извлечь code поле из ошибки и обрабатывать её в соответствии
 * с кодом. Доступные коды на данный момент:
 *
 * `SERVER_ERROR` - общая ошибка сервера
 *
 * `NOT_FOUND_ERROR` - ошибка получения данных
 *
 * `UNAUTHORIZED_ERROR` - ошибка авторизации
 *
 * `VALIDATION_ERROR` - ошибка валидации
 */
export type MutationsCartPriceArgs = {
  items: Array<Maybe<ItemsInputType>>;
  promoCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  stateUuid: Scalars['UUID'];
};

/** An object with an ID */
export type Node = {
  /** The ID of the object. */
  id: Scalars['ID'];
};

/** Получение данных оператора. */
export type OperatorAuthMutation = {
  __typename?: 'OperatorAuthMutation';
  ok: Scalars['Boolean'];
  errors: Array<Maybe<Scalars['String']>>;
  /** Идентификатор */
  id?: Maybe<Scalars['ID']>;
  operator?: Maybe<OperatorType>;
};

/** Данные оператора. */
export type OperatorType = {
  __typename?: 'OperatorType';
  /** ID оператора */
  userId?: Maybe<Scalars['ID']>;
};

/** Авторизационные данные Провайдера Платежа */
export type PpAuthDataType = Node & {
  __typename?: 'PPAuthDataType';
  /** The ID of the object. */
  id: Scalars['ID'];
  version: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
  orgId: Scalars['Int'];
  paymentType: PaymentProviderAuthDataPaymentType;
  authToken: Scalars['String'];
  authId: Scalars['String'];
  mcc: Scalars['String'];
  terminals: TerminalListTypeConnection;
  active: Scalars['Boolean'];
};


/** Авторизационные данные Провайдера Платежа */
export type PpAuthDataTypeTerminalsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  status?: Maybe<Scalars['String']>;
};

export type PpAuthDataTypeConnection = {
  __typename?: 'PPAuthDataTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PpAuthDataTypeEdge>>;
};

/** A Relay edge containing a `PPAuthDataType` and its cursor. */
export type PpAuthDataTypeEdge = {
  __typename?: 'PPAuthDataTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<PpAuthDataType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type PackProductType = Node & {
  __typename?: 'PackProductType';
  /** The ID of the object. */
  id: Scalars['ID'];
  version: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
  pack: PackType;
  product: ProductType;
  count: Scalars['Int'];
};

export type PackProductTypeConnection = {
  __typename?: 'PackProductTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PackProductTypeEdge>>;
};

/** A Relay edge containing a `PackProductType` and its cursor. */
export type PackProductTypeEdge = {
  __typename?: 'PackProductTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<PackProductType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type PackType = Node & {
  __typename?: 'PackType';
  /** The ID of the object. */
  id: Scalars['ID'];
  version: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
  orgId: Scalars['Int'];
  uuid1c?: Maybe<Scalars['String']>;
  /** Код для сканирования */
  barcode: Scalars['String'];
  packedProducts?: Maybe<Array<Maybe<PackProductType>>>;
};

export type PackTypeConnection = {
  __typename?: 'PackTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PackTypeEdge>>;
};

/** A Relay edge containing a `PackType` and its cursor. */
export type PackTypeEdge = {
  __typename?: 'PackTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<PackType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
};

/** Оплата корзины. */
export type PayCartMutation = {
  __typename?: 'PayCartMutation';
  ok: Scalars['Boolean'];
  errors: Array<Maybe<Scalars['String']>>;
  /** Идентификатор */
  id?: Maybe<Scalars['ID']>;
};

/** An enumeration. */
export enum PaymentProviderAuthDataPaymentType {
  /** Сбербанк */
  Sberbank = 'SBERBANK',
  /** Промсвязьбанк */
  Psbank = 'PSBANK',
  /** Tбанк */
  Tbank = 'TBANK',
  /** Фуд карты */
  Foodcard = 'FOODCARD',
  /** Яндекс ГО */
  Yandexgo = 'YANDEXGO',
  /** На ланч */
  Nalunch = 'NALUNCH',
  /** Яндекс Бейдж */
  Yandexbadge = 'YANDEXBADGE',
  /** ОбедРу */
  Obedru = 'OBEDRU'
}

/** An enumeration. */
export enum PaymentProviderTitle {
  /** Сбербанк */
  Sberbank = 'SBERBANK',
  /** Промсвязьбанк */
  Psbank = 'PSBANK',
  /** Tбанк */
  Tbank = 'TBANK',
  /** Фуд карты */
  Foodcard = 'FOODCARD',
  /** Яндекс ГО */
  Yandexgo = 'YANDEXGO',
  /** На ланч */
  Nalunch = 'NALUNCH',
  /** Яндекс Бейдж */
  Yandexbadge = 'YANDEXBADGE',
  /** ОбедРу */
  Obedru = 'OBEDRU'
}

/** Провайдер Платежа */
export type PaymentProviderType = Node & {
  __typename?: 'PaymentProviderType';
  /** The ID of the object. */
  id: Scalars['ID'];
  title: PaymentProviderTitle;
  active: Scalars['Boolean'];
  /** Сделать тип оплаты обязательным, как минимум один способ оплаты не терминале должен быть обязательным */
  required: Scalars['Boolean'];
  /** Можно выбрать не больше одного дополнительного провайдера оплаты! */
  terminals: TerminalListTypeConnection;
};


/** Провайдер Платежа */
export type PaymentProviderTypeTerminalsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  status?: Maybe<Scalars['String']>;
};

export type PaymentProviderTypeConnection = {
  __typename?: 'PaymentProviderTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PaymentProviderTypeEdge>>;
};

/** A Relay edge containing a `PaymentProviderType` and its cursor. */
export type PaymentProviderTypeEdge = {
  __typename?: 'PaymentProviderTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<PaymentProviderType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** Тип Оплаты */
export enum PaymentTypes {
  Sberbank = 'SBERBANK',
  Psbank = 'PSBANK',
  Tbank = 'TBANK',
  Foodcard = 'FOODCARD',
  Yandexgo = 'YANDEXGO',
  Nalunch = 'NALUNCH',
  Yandexbadge = 'YANDEXBADGE',
  Obedru = 'OBEDRU'
}

/** Модификаторы цен */
export type PriceModificationType = Node & {
  __typename?: 'PriceModificationType';
  /** The ID of the object. */
  id: Scalars['ID'];
  version: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
  /** Товар, к которому применяется скидка */
  product?: Maybe<ProductType>;
  /** будет применяться только к скоропртящимся продуктам. */
  perishable: Scalars['Boolean'];
  /** Терминал, к которому применяется скидка (Если не указывать, скидка применится ко всем терминалам) */
  terminal?: Maybe<TerminalListType>;
  /** Терминалы, на которые не распространяется скидка */
  excludedTerminals: TerminalListTypeConnection;
  /** Наименование не обязательно, но поможет при анализе выданных скидок */
  title?: Maybe<Scalars['String']>;
  /** Процент от цены. Для скидки введите отрицательное значение. Не сочетается с фиксированным значением */
  percent?: Maybe<Scalars['Float']>;
  /** Положительное значение дает фиксированную надбавку в рублях, отрицательное скидку. Не сочетается с процентом */
  fixed?: Maybe<Scalars['Float']>;
  /** Нижняя граница применения скидки */
  dtFrom?: Maybe<Scalars['DateTime']>;
  /** верхняя граница применения скидки */
  dtTo?: Maybe<Scalars['DateTime']>;
  /** если выбрать время без даты, то будет выполнение условия каждый день */
  tFrom?: Maybe<Scalars['Time']>;
  /** если выбрать время без даты, то будет выполнение условия каждый день */
  tTo?: Maybe<Scalars['Time']>;
  /** условие применяется только если скидка применяется к корзине */
  fromChatBot: Scalars['Boolean'];
  year?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  day?: Maybe<Scalars['Int']>;
  weekday?: Maybe<PriceModificationWeekday>;
  /** Можно выбрать каталог, вместо продукта. */
  catalog: CatalogTypeConnection;
  /** Кол-во товаров данного вида в терминале (равно или больше), при условии которого применяется скидка. */
  count?: Maybe<Scalars['Int']>;
  /** Скидка по штрих коду с пометкой акционный. Чтобы значения появились для выбора укажите флаг "акционный" в штрих коде.  */
  scanCodes: ScanCodeTypeConnection;
};


/** Модификаторы цен */
export type PriceModificationTypeExcludedTerminalsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  status?: Maybe<Scalars['String']>;
};


/** Модификаторы цен */
export type PriceModificationTypeCatalogArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Модификаторы цен */
export type PriceModificationTypeScanCodesArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  action?: Maybe<Scalars['Boolean']>;
};

export type PriceModificationTypeConnection = {
  __typename?: 'PriceModificationTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PriceModificationTypeEdge>>;
};

/** A Relay edge containing a `PriceModificationType` and its cursor. */
export type PriceModificationTypeEdge = {
  __typename?: 'PriceModificationTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<PriceModificationType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type PriceModificationUpdatedSubscription = {
  __typename?: 'PriceModificationUpdatedSubscription';
  priceModifications?: Maybe<Array<Maybe<PriceModificationType>>>;
};

/** An enumeration. */
export enum PriceModificationWeekday {
  /** Понедельник */
  A_1 = 'A_1',
  /** Вторник */
  A_2 = 'A_2',
  /** Среда */
  A_3 = 'A_3',
  /** Четверг */
  A_4 = 'A_4',
  /** Пятница */
  A_5 = 'A_5',
  /** Суббота */
  A_6 = 'A_6',
  /** Воскресенье */
  A_7 = 'A_7'
}

export type ProductCategoryAddedSubscription = {
  __typename?: 'ProductCategoryAddedSubscription';
  productCategory?: Maybe<ProductCategoryType>;
};

export type ProductCategoryDeletedSubscription = {
  __typename?: 'ProductCategoryDeletedSubscription';
  productCategory?: Maybe<ProductCategoryType>;
};

/** Категория товаров. */
export type ProductCategoryType = Node & {
  __typename?: 'ProductCategoryType';
  /** The ID of the object. */
  id: Scalars['ID'];
  version: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
  orgId: Scalars['Int'];
  uuid: Scalars['UUID'];
  title: Scalars['String'];
  productsubcategorySet: ProductSubCategoryTypeConnection;
};


/** Категория товаров. */
export type ProductCategoryTypeProductsubcategorySetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
};

export type ProductCategoryTypeConnection = {
  __typename?: 'ProductCategoryTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProductCategoryTypeEdge>>;
};

/** A Relay edge containing a `ProductCategoryType` and its cursor. */
export type ProductCategoryTypeEdge = {
  __typename?: 'ProductCategoryTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<ProductCategoryType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type ProductCategoryUpdatedSubscription = {
  __typename?: 'ProductCategoryUpdatedSubscription';
  productCategory?: Maybe<ProductCategoryType>;
};

export type ProductSubCategoryAddedSubscription = {
  __typename?: 'ProductSubCategoryAddedSubscription';
  productCategory?: Maybe<ProductSubCategoryType>;
};

export type ProductSubCategoryDeletedSubscription = {
  __typename?: 'ProductSubCategoryDeletedSubscription';
  productCategory?: Maybe<ProductSubCategoryType>;
};

/** Подкатегория товаров. */
export type ProductSubCategoryType = Node & {
  __typename?: 'ProductSubCategoryType';
  /** The ID of the object. */
  id: Scalars['ID'];
  version: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
  orgId: Scalars['Int'];
  category: ProductCategoryTypeConnection;
  uuid: Scalars['UUID'];
  title: Scalars['String'];
  productSet: ProductTypeConnection;
};


/** Подкатегория товаров. */
export type ProductSubCategoryTypeCategoryArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
};


/** Подкатегория товаров. */
export type ProductSubCategoryTypeProductSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type ProductSubCategoryTypeConnection = {
  __typename?: 'ProductSubCategoryTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProductSubCategoryTypeEdge>>;
};

/** A Relay edge containing a `ProductSubCategoryType` and its cursor. */
export type ProductSubCategoryTypeEdge = {
  __typename?: 'ProductSubCategoryTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<ProductSubCategoryType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type ProductSubCategoryUpdatedSubscription = {
  __typename?: 'ProductSubCategoryUpdatedSubscription';
  productCategory?: Maybe<ProductSubCategoryType>;
};

/** Номенклатуры.  */
export type ProductType = Node & {
  __typename?: 'ProductType';
  /** The ID of the object. */
  id: Scalars['ID'];
  version: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
  orgId: Scalars['Int'];
  subcategory: ProductSubCategoryTypeConnection;
  catalog?: Maybe<CatalogType>;
  uuid: Scalars['UUID'];
  title: Scalars['String'];
  ingredients: Scalars['String'];
  weight: Scalars['Float'];
  calories: Scalars['Float'];
  fats: Scalars['Float'];
  carbohydrates: Scalars['Float'];
  proteins: Scalars['Float'];
  img?: Maybe<Scalars['String']>;
  imgFileSizeKb: Scalars['Float'];
  netPrice: Scalars['Float'];
  salePrice: Scalars['Float'];
  /** Устаревшее поле */
  barcode: Scalars['String'];
  isPerishable: Scalars['Boolean'];
  storageConditions: Scalars['String'];
  vegeterian: Scalars['Boolean'];
  spicy: Scalars['Boolean'];
  sugarFree: Scalars['Boolean'];
  oilFree: Scalars['Boolean'];
  glutenFree: Scalars['Boolean'];
  lowCalories: Scalars['Boolean'];
  uuid1c?: Maybe<Scalars['String']>;
  gtin?: Maybe<Scalars['String']>;
  isExcise: Scalars['Boolean'];
  vat?: Maybe<ProductVat>;
  /** Связанный продукт */
  scancodeSet: ScanCodeTypeConnection;
  /** Товар, к которому применяется скидка */
  pricemodificationSet: PriceModificationTypeConnection;
  batchSet: BatchTypeConnection;
  /** Вид товара */
  assortmentSet: LeftoverTypeConnection;
  packs: PackProductTypeConnection;
};


/** Номенклатуры.  */
export type ProductTypeSubcategoryArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
};


/** Номенклатуры.  */
export type ProductTypeScancodeSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  action?: Maybe<Scalars['Boolean']>;
};


/** Номенклатуры.  */
export type ProductTypePricemodificationSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
};


/** Номенклатуры.  */
export type ProductTypeBatchSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
};


/** Номенклатуры.  */
export type ProductTypeAssortmentSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  product_Id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** Номенклатуры.  */
export type ProductTypePacksArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type ProductTypeConnection = {
  __typename?: 'ProductTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProductTypeEdge>>;
};

/** A Relay edge containing a `ProductType` and its cursor. */
export type ProductTypeEdge = {
  __typename?: 'ProductTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<ProductType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum ProductVat {
  /** Без НДС */
  Vatno = 'VATNO',
  /** НДС 10% */
  Vat10 = 'VAT10',
  /** НДС 20% */
  Vat20 = 'VAT20'
}

/**
 * Запросы на получение различных объектов системы.
 *
 * Для использования сортировки по полям, указывать `orderBy` и поле по которому сортируем.
 *
 * Например:
 *
 * `terminalLog(orderBy: "-id")`
 * где `id` соответствует ASC (по возрастанию),
 *
 * а `-id` соответствует DESC (по убыванию).
 *
 * Также можно указывать несколько полей для сортировки:
 *
 * `terminalLog(orderBy: ["-createdAt", "-id"]`
 *
 *
 * При возникновении ошибок не обходимо извлечь code поле из ошибки и обрабатывать её в соответствии
 * с кодом. Доступные коды на данный момент:
 *
 * `SERVER_ERROR` - общая ошибка сервера
 *
 * `NOT_FOUND_ERROR` - ошибка получения данных
 *
 * `UNAUTHORIZED_ERROR` - ошибка авторизации
 *
 * `VALIDATION_ERROR` - ошибка валидации
 */
export type Query = {
  __typename?: 'Query';
  /** Тестирование доступности */
  ping?: Maybe<Scalars['String']>;
  cartPrice?: Maybe<CartPriceTypeConnection>;
  scancode?: Maybe<ScanCodeTypeConnection>;
  pack?: Maybe<PackTypeConnection>;
  productCategory?: Maybe<ProductCategoryTypeConnection>;
  productSubcategory?: Maybe<ProductSubCategoryTypeConnection>;
  terminalLogs?: Maybe<TerminalLogTypeConnection>;
  leftovers?: Maybe<LeftoverTypeConnection>;
  carts?: Maybe<CartTypeConnection>;
  customers?: Maybe<CustomerTypeConnection>;
  customerFeedbacks?: Maybe<CustomerFeedbackTypeConnection>;
  batches?: Maybe<BatchTypeConnection>;
  batchTypes?: Maybe<ShipmentBatchTypeConnection>;
  shipments?: Maybe<ShipmentTypeConnection>;
  items?: Maybe<ItemTypeConnection>;
  terminal?: Maybe<TerminalTypeConnection>;
  terminals?: Maybe<TerminalListTypeConnection>;
  priceModification?: Maybe<PriceModificationTypeConnection>;
};


/**
 * Запросы на получение различных объектов системы.
 *
 * Для использования сортировки по полям, указывать `orderBy` и поле по которому сортируем.
 *
 * Например:
 *
 * `terminalLog(orderBy: "-id")`
 * где `id` соответствует ASC (по возрастанию),
 *
 * а `-id` соответствует DESC (по убыванию).
 *
 * Также можно указывать несколько полей для сортировки:
 *
 * `terminalLog(orderBy: ["-createdAt", "-id"]`
 *
 *
 * При возникновении ошибок не обходимо извлечь code поле из ошибки и обрабатывать её в соответствии
 * с кодом. Доступные коды на данный момент:
 *
 * `SERVER_ERROR` - общая ошибка сервера
 *
 * `NOT_FOUND_ERROR` - ошибка получения данных
 *
 * `UNAUTHORIZED_ERROR` - ошибка авторизации
 *
 * `VALIDATION_ERROR` - ошибка валидации
 */
export type QueryCartPriceArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/**
 * Запросы на получение различных объектов системы.
 *
 * Для использования сортировки по полям, указывать `orderBy` и поле по которому сортируем.
 *
 * Например:
 *
 * `terminalLog(orderBy: "-id")`
 * где `id` соответствует ASC (по возрастанию),
 *
 * а `-id` соответствует DESC (по убыванию).
 *
 * Также можно указывать несколько полей для сортировки:
 *
 * `terminalLog(orderBy: ["-createdAt", "-id"]`
 *
 *
 * При возникновении ошибок не обходимо извлечь code поле из ошибки и обрабатывать её в соответствии
 * с кодом. Доступные коды на данный момент:
 *
 * `SERVER_ERROR` - общая ошибка сервера
 *
 * `NOT_FOUND_ERROR` - ошибка получения данных
 *
 * `UNAUTHORIZED_ERROR` - ошибка авторизации
 *
 * `VALIDATION_ERROR` - ошибка валидации
 */
export type QueryScancodeArgs = {
  orderBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  action?: Maybe<Scalars['Boolean']>;
};


/**
 * Запросы на получение различных объектов системы.
 *
 * Для использования сортировки по полям, указывать `orderBy` и поле по которому сортируем.
 *
 * Например:
 *
 * `terminalLog(orderBy: "-id")`
 * где `id` соответствует ASC (по возрастанию),
 *
 * а `-id` соответствует DESC (по убыванию).
 *
 * Также можно указывать несколько полей для сортировки:
 *
 * `terminalLog(orderBy: ["-createdAt", "-id"]`
 *
 *
 * При возникновении ошибок не обходимо извлечь code поле из ошибки и обрабатывать её в соответствии
 * с кодом. Доступные коды на данный момент:
 *
 * `SERVER_ERROR` - общая ошибка сервера
 *
 * `NOT_FOUND_ERROR` - ошибка получения данных
 *
 * `UNAUTHORIZED_ERROR` - ошибка авторизации
 *
 * `VALIDATION_ERROR` - ошибка валидации
 */
export type QueryPackArgs = {
  orderBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  barcode?: Maybe<Scalars['String']>;
};


/**
 * Запросы на получение различных объектов системы.
 *
 * Для использования сортировки по полям, указывать `orderBy` и поле по которому сортируем.
 *
 * Например:
 *
 * `terminalLog(orderBy: "-id")`
 * где `id` соответствует ASC (по возрастанию),
 *
 * а `-id` соответствует DESC (по убыванию).
 *
 * Также можно указывать несколько полей для сортировки:
 *
 * `terminalLog(orderBy: ["-createdAt", "-id"]`
 *
 *
 * При возникновении ошибок не обходимо извлечь code поле из ошибки и обрабатывать её в соответствии
 * с кодом. Доступные коды на данный момент:
 *
 * `SERVER_ERROR` - общая ошибка сервера
 *
 * `NOT_FOUND_ERROR` - ошибка получения данных
 *
 * `UNAUTHORIZED_ERROR` - ошибка авторизации
 *
 * `VALIDATION_ERROR` - ошибка валидации
 */
export type QueryProductCategoryArgs = {
  orderBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
};


/**
 * Запросы на получение различных объектов системы.
 *
 * Для использования сортировки по полям, указывать `orderBy` и поле по которому сортируем.
 *
 * Например:
 *
 * `terminalLog(orderBy: "-id")`
 * где `id` соответствует ASC (по возрастанию),
 *
 * а `-id` соответствует DESC (по убыванию).
 *
 * Также можно указывать несколько полей для сортировки:
 *
 * `terminalLog(orderBy: ["-createdAt", "-id"]`
 *
 *
 * При возникновении ошибок не обходимо извлечь code поле из ошибки и обрабатывать её в соответствии
 * с кодом. Доступные коды на данный момент:
 *
 * `SERVER_ERROR` - общая ошибка сервера
 *
 * `NOT_FOUND_ERROR` - ошибка получения данных
 *
 * `UNAUTHORIZED_ERROR` - ошибка авторизации
 *
 * `VALIDATION_ERROR` - ошибка валидации
 */
export type QueryProductSubcategoryArgs = {
  orderBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
};


/**
 * Запросы на получение различных объектов системы.
 *
 * Для использования сортировки по полям, указывать `orderBy` и поле по которому сортируем.
 *
 * Например:
 *
 * `terminalLog(orderBy: "-id")`
 * где `id` соответствует ASC (по возрастанию),
 *
 * а `-id` соответствует DESC (по убыванию).
 *
 * Также можно указывать несколько полей для сортировки:
 *
 * `terminalLog(orderBy: ["-createdAt", "-id"]`
 *
 *
 * При возникновении ошибок не обходимо извлечь code поле из ошибки и обрабатывать её в соответствии
 * с кодом. Доступные коды на данный момент:
 *
 * `SERVER_ERROR` - общая ошибка сервера
 *
 * `NOT_FOUND_ERROR` - ошибка получения данных
 *
 * `UNAUTHORIZED_ERROR` - ошибка авторизации
 *
 * `VALIDATION_ERROR` - ошибка валидации
 */
export type QueryTerminalLogsArgs = {
  orderBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
};


/**
 * Запросы на получение различных объектов системы.
 *
 * Для использования сортировки по полям, указывать `orderBy` и поле по которому сортируем.
 *
 * Например:
 *
 * `terminalLog(orderBy: "-id")`
 * где `id` соответствует ASC (по возрастанию),
 *
 * а `-id` соответствует DESC (по убыванию).
 *
 * Также можно указывать несколько полей для сортировки:
 *
 * `terminalLog(orderBy: ["-createdAt", "-id"]`
 *
 *
 * При возникновении ошибок не обходимо извлечь code поле из ошибки и обрабатывать её в соответствии
 * с кодом. Доступные коды на данный момент:
 *
 * `SERVER_ERROR` - общая ошибка сервера
 *
 * `NOT_FOUND_ERROR` - ошибка получения данных
 *
 * `UNAUTHORIZED_ERROR` - ошибка авторизации
 *
 * `VALIDATION_ERROR` - ошибка валидации
 */
export type QueryLeftoversArgs = {
  orderBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  product_Id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/**
 * Запросы на получение различных объектов системы.
 *
 * Для использования сортировки по полям, указывать `orderBy` и поле по которому сортируем.
 *
 * Например:
 *
 * `terminalLog(orderBy: "-id")`
 * где `id` соответствует ASC (по возрастанию),
 *
 * а `-id` соответствует DESC (по убыванию).
 *
 * Также можно указывать несколько полей для сортировки:
 *
 * `terminalLog(orderBy: ["-createdAt", "-id"]`
 *
 *
 * При возникновении ошибок не обходимо извлечь code поле из ошибки и обрабатывать её в соответствии
 * с кодом. Доступные коды на данный момент:
 *
 * `SERVER_ERROR` - общая ошибка сервера
 *
 * `NOT_FOUND_ERROR` - ошибка получения данных
 *
 * `UNAUTHORIZED_ERROR` - ошибка авторизации
 *
 * `VALIDATION_ERROR` - ошибка валидации
 */
export type QueryCartsArgs = {
  orderBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  fromChatBot?: Maybe<Scalars['Boolean']>;
};


/**
 * Запросы на получение различных объектов системы.
 *
 * Для использования сортировки по полям, указывать `orderBy` и поле по которому сортируем.
 *
 * Например:
 *
 * `terminalLog(orderBy: "-id")`
 * где `id` соответствует ASC (по возрастанию),
 *
 * а `-id` соответствует DESC (по убыванию).
 *
 * Также можно указывать несколько полей для сортировки:
 *
 * `terminalLog(orderBy: ["-createdAt", "-id"]`
 *
 *
 * При возникновении ошибок не обходимо извлечь code поле из ошибки и обрабатывать её в соответствии
 * с кодом. Доступные коды на данный момент:
 *
 * `SERVER_ERROR` - общая ошибка сервера
 *
 * `NOT_FOUND_ERROR` - ошибка получения данных
 *
 * `UNAUTHORIZED_ERROR` - ошибка авторизации
 *
 * `VALIDATION_ERROR` - ошибка валидации
 */
export type QueryCustomersArgs = {
  orderBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
};


/**
 * Запросы на получение различных объектов системы.
 *
 * Для использования сортировки по полям, указывать `orderBy` и поле по которому сортируем.
 *
 * Например:
 *
 * `terminalLog(orderBy: "-id")`
 * где `id` соответствует ASC (по возрастанию),
 *
 * а `-id` соответствует DESC (по убыванию).
 *
 * Также можно указывать несколько полей для сортировки:
 *
 * `terminalLog(orderBy: ["-createdAt", "-id"]`
 *
 *
 * При возникновении ошибок не обходимо извлечь code поле из ошибки и обрабатывать её в соответствии
 * с кодом. Доступные коды на данный момент:
 *
 * `SERVER_ERROR` - общая ошибка сервера
 *
 * `NOT_FOUND_ERROR` - ошибка получения данных
 *
 * `UNAUTHORIZED_ERROR` - ошибка авторизации
 *
 * `VALIDATION_ERROR` - ошибка валидации
 */
export type QueryCustomerFeedbacksArgs = {
  orderBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
};


/**
 * Запросы на получение различных объектов системы.
 *
 * Для использования сортировки по полям, указывать `orderBy` и поле по которому сортируем.
 *
 * Например:
 *
 * `terminalLog(orderBy: "-id")`
 * где `id` соответствует ASC (по возрастанию),
 *
 * а `-id` соответствует DESC (по убыванию).
 *
 * Также можно указывать несколько полей для сортировки:
 *
 * `terminalLog(orderBy: ["-createdAt", "-id"]`
 *
 *
 * При возникновении ошибок не обходимо извлечь code поле из ошибки и обрабатывать её в соответствии
 * с кодом. Доступные коды на данный момент:
 *
 * `SERVER_ERROR` - общая ошибка сервера
 *
 * `NOT_FOUND_ERROR` - ошибка получения данных
 *
 * `UNAUTHORIZED_ERROR` - ошибка авторизации
 *
 * `VALIDATION_ERROR` - ошибка валидации
 */
export type QueryBatchesArgs = {
  orderBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
};


/**
 * Запросы на получение различных объектов системы.
 *
 * Для использования сортировки по полям, указывать `orderBy` и поле по которому сортируем.
 *
 * Например:
 *
 * `terminalLog(orderBy: "-id")`
 * где `id` соответствует ASC (по возрастанию),
 *
 * а `-id` соответствует DESC (по убыванию).
 *
 * Также можно указывать несколько полей для сортировки:
 *
 * `terminalLog(orderBy: ["-createdAt", "-id"]`
 *
 *
 * При возникновении ошибок не обходимо извлечь code поле из ошибки и обрабатывать её в соответствии
 * с кодом. Доступные коды на данный момент:
 *
 * `SERVER_ERROR` - общая ошибка сервера
 *
 * `NOT_FOUND_ERROR` - ошибка получения данных
 *
 * `UNAUTHORIZED_ERROR` - ошибка авторизации
 *
 * `VALIDATION_ERROR` - ошибка валидации
 */
export type QueryBatchTypesArgs = {
  orderBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
};


/**
 * Запросы на получение различных объектов системы.
 *
 * Для использования сортировки по полям, указывать `orderBy` и поле по которому сортируем.
 *
 * Например:
 *
 * `terminalLog(orderBy: "-id")`
 * где `id` соответствует ASC (по возрастанию),
 *
 * а `-id` соответствует DESC (по убыванию).
 *
 * Также можно указывать несколько полей для сортировки:
 *
 * `terminalLog(orderBy: ["-createdAt", "-id"]`
 *
 *
 * При возникновении ошибок не обходимо извлечь code поле из ошибки и обрабатывать её в соответствии
 * с кодом. Доступные коды на данный момент:
 *
 * `SERVER_ERROR` - общая ошибка сервера
 *
 * `NOT_FOUND_ERROR` - ошибка получения данных
 *
 * `UNAUTHORIZED_ERROR` - ошибка авторизации
 *
 * `VALIDATION_ERROR` - ошибка валидации
 */
export type QueryShipmentsArgs = {
  orderBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
};


/**
 * Запросы на получение различных объектов системы.
 *
 * Для использования сортировки по полям, указывать `orderBy` и поле по которому сортируем.
 *
 * Например:
 *
 * `terminalLog(orderBy: "-id")`
 * где `id` соответствует ASC (по возрастанию),
 *
 * а `-id` соответствует DESC (по убыванию).
 *
 * Также можно указывать несколько полей для сортировки:
 *
 * `terminalLog(orderBy: ["-createdAt", "-id"]`
 *
 *
 * При возникновении ошибок не обходимо извлечь code поле из ошибки и обрабатывать её в соответствии
 * с кодом. Доступные коды на данный момент:
 *
 * `SERVER_ERROR` - общая ошибка сервера
 *
 * `NOT_FOUND_ERROR` - ошибка получения данных
 *
 * `UNAUTHORIZED_ERROR` - ошибка авторизации
 *
 * `VALIDATION_ERROR` - ошибка валидации
 */
export type QueryItemsArgs = {
  orderBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
};


/**
 * Запросы на получение различных объектов системы.
 *
 * Для использования сортировки по полям, указывать `orderBy` и поле по которому сортируем.
 *
 * Например:
 *
 * `terminalLog(orderBy: "-id")`
 * где `id` соответствует ASC (по возрастанию),
 *
 * а `-id` соответствует DESC (по убыванию).
 *
 * Также можно указывать несколько полей для сортировки:
 *
 * `terminalLog(orderBy: ["-createdAt", "-id"]`
 *
 *
 * При возникновении ошибок не обходимо извлечь code поле из ошибки и обрабатывать её в соответствии
 * с кодом. Доступные коды на данный момент:
 *
 * `SERVER_ERROR` - общая ошибка сервера
 *
 * `NOT_FOUND_ERROR` - ошибка получения данных
 *
 * `UNAUTHORIZED_ERROR` - ошибка авторизации
 *
 * `VALIDATION_ERROR` - ошибка валидации
 */
export type QueryTerminalArgs = {
  orderBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
};


/**
 * Запросы на получение различных объектов системы.
 *
 * Для использования сортировки по полям, указывать `orderBy` и поле по которому сортируем.
 *
 * Например:
 *
 * `terminalLog(orderBy: "-id")`
 * где `id` соответствует ASC (по возрастанию),
 *
 * а `-id` соответствует DESC (по убыванию).
 *
 * Также можно указывать несколько полей для сортировки:
 *
 * `terminalLog(orderBy: ["-createdAt", "-id"]`
 *
 *
 * При возникновении ошибок не обходимо извлечь code поле из ошибки и обрабатывать её в соответствии
 * с кодом. Доступные коды на данный момент:
 *
 * `SERVER_ERROR` - общая ошибка сервера
 *
 * `NOT_FOUND_ERROR` - ошибка получения данных
 *
 * `UNAUTHORIZED_ERROR` - ошибка авторизации
 *
 * `VALIDATION_ERROR` - ошибка валидации
 */
export type QueryTerminalsArgs = {
  orderBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  status?: Maybe<Scalars['String']>;
};


/**
 * Запросы на получение различных объектов системы.
 *
 * Для использования сортировки по полям, указывать `orderBy` и поле по которому сортируем.
 *
 * Например:
 *
 * `terminalLog(orderBy: "-id")`
 * где `id` соответствует ASC (по возрастанию),
 *
 * а `-id` соответствует DESC (по убыванию).
 *
 * Также можно указывать несколько полей для сортировки:
 *
 * `terminalLog(orderBy: ["-createdAt", "-id"]`
 *
 *
 * При возникновении ошибок не обходимо извлечь code поле из ошибки и обрабатывать её в соответствии
 * с кодом. Доступные коды на данный момент:
 *
 * `SERVER_ERROR` - общая ошибка сервера
 *
 * `NOT_FOUND_ERROR` - ошибка получения данных
 *
 * `UNAUTHORIZED_ERROR` - ошибка авторизации
 *
 * `VALIDATION_ERROR` - ошибка валидации
 */
export type QueryPriceModificationArgs = {
  orderBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
};

/** Авторизация терминала в системе. */
export type RegistrationTerminalMutation = {
  __typename?: 'RegistrationTerminalMutation';
  ok: Scalars['Boolean'];
  errors: Array<Maybe<Scalars['String']>>;
  /** Идентификатор */
  id?: Maybe<Scalars['ID']>;
};

/** Удаление элемента из корзины. */
export type RemoveItemCartMutation = {
  __typename?: 'RemoveItemCartMutation';
  ok: Scalars['Boolean'];
  errors: Array<Maybe<Scalars['String']>>;
  /** Идентификатор */
  id?: Maybe<Scalars['ID']>;
};

export type ScanCodeType = Node & {
  __typename?: 'ScanCodeType';
  createdAt: Scalars['DateTime'];
  /** Связанный продукт */
  product: ProductType;
  /** Код для сканирования */
  code: Scalars['String'];
  /** Является промокодом на скидку */
  action: Scalars['Boolean'];
  /** Скидка по штрих коду с пометкой акционный. Чтобы значения появились для выбора укажите флаг "акционный" в штрих коде.  */
  pricemodificationSet: PriceModificationTypeConnection;
  /** The ID of the object. */
  id: Scalars['ID'];
};


export type ScanCodeTypePricemodificationSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
};

export type ScanCodeTypeConnection = {
  __typename?: 'ScanCodeTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ScanCodeTypeEdge>>;
};

/** A Relay edge containing a `ScanCodeType` and its cursor. */
export type ScanCodeTypeEdge = {
  __typename?: 'ScanCodeTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<ScanCodeType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** Перевод корзины в статус ожидания оплаты. */
export type SetWaitingForPaymentStatusCartMutation = {
  __typename?: 'SetWaitingForPaymentStatusCartMutation';
  ok: Scalars['Boolean'];
  errors: Array<Maybe<Scalars['String']>>;
  /** Идентификатор */
  id?: Maybe<Scalars['ID']>;
};

/** Партии товара в отгрузке */
export type ShipmentBatchType = Node & {
  __typename?: 'ShipmentBatchType';
  /** The ID of the object. */
  id: Scalars['ID'];
  version: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
  shipment: ShipmentType;
  batch: BatchType;
  position?: Maybe<Scalars['String']>;
  count: Scalars['Int'];
  actualCount: Scalars['Int'];
  returnedCount: Scalars['Int'];
};

export type ShipmentBatchTypeConnection = {
  __typename?: 'ShipmentBatchTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ShipmentBatchTypeEdge>>;
};

/** A Relay edge containing a `ShipmentBatchType` and its cursor. */
export type ShipmentBatchTypeEdge = {
  __typename?: 'ShipmentBatchTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<ShipmentBatchType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum ShipmentShipmentType {
  /** Отгрузка */
  Shipment = 'SHIPMENT',
  /** Инвентаризация */
  Inventory = 'INVENTORY',
  /** Инвентаризация через ТСД */
  DctInventory = 'DCT_INVENTORY',
  /** Списание из терминала */
  WriteOff = 'WRITE_OFF',
  /** Загрузка из терминала */
  TerminalLoad = 'TERMINAL_LOAD',
  /** Загрузка через ТСД */
  DctLoad = 'DCT_LOAD',
  /** Списание через ТСД */
  DctWriteOff = 'DCT_WRITE_OFF',
  /** Снятие на склад через ТСД */
  DctReturnToStorage = 'DCT_RETURN_TO_STORAGE',
  /** Перемещение между терминалами */
  Movement = 'MOVEMENT'
}

/** An enumeration. */
export enum ShipmentStatus {
  /** Создана */
  A_0 = 'A_0',
  /** Назначена */
  A_1 = 'A_1',
  /** В процессе */
  A_2 = 'A_2',
  /** Выполнена */
  A_3 = 'A_3'
}

/** Отгрузки */
export type ShipmentType = Node & {
  __typename?: 'ShipmentType';
  /** The ID of the object. */
  id: Scalars['ID'];
  version: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
  terminal: TerminalListType;
  stock: TerminalListType;
  status: ShipmentStatus;
  dateTodo?: Maybe<Scalars['Date']>;
  uid: Scalars['UUID'];
  isNotificationSent: Scalars['Boolean'];
  notificationSentAt?: Maybe<Scalars['DateTime']>;
  isInventory: Scalars['Boolean'];
  shipmentType: ShipmentShipmentType;
  shipment1c?: Maybe<ShipmentType>;
  isComparedWithStorage: Scalars['Boolean'];
  documentUid?: Maybe<Scalars['String']>;
  assignedAt?: Maybe<Scalars['DateTime']>;
  inProgressAt?: Maybe<Scalars['DateTime']>;
  terminalShipments: ShipmentTypeConnection;
  shipmentbatchSet: ShipmentBatchTypeConnection;
  itemSet: ItemTypeConnection;
};


/** Отгрузки */
export type ShipmentTypeTerminalShipmentsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
};


/** Отгрузки */
export type ShipmentTypeShipmentbatchSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
};


/** Отгрузки */
export type ShipmentTypeItemSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
};

export type ShipmentTypeConnection = {
  __typename?: 'ShipmentTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ShipmentTypeEdge>>;
};

/** A Relay edge containing a `ShipmentType` and its cursor. */
export type ShipmentTypeEdge = {
  __typename?: 'ShipmentTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<ShipmentType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** Рекламные баннеры на терминалах. */
export type TerminalAdBannerType = Node & {
  __typename?: 'TerminalADBannerType';
  /** The ID of the object. */
  id: Scalars['ID'];
  version: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
  orgId: Scalars['Int'];
  title: Scalars['String'];
  /** Изображение баннера */
  image: Scalars['String'];
  /** Выбранные терминалы */
  terminals: TerminalListTypeConnection;
};


/** Рекламные баннеры на терминалах. */
export type TerminalAdBannerTypeTerminalsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  status?: Maybe<Scalars['String']>;
};

export type TerminalAdBannerTypeConnection = {
  __typename?: 'TerminalADBannerTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TerminalAdBannerTypeEdge>>;
};

/** A Relay edge containing a `TerminalADBannerType` and its cursor. */
export type TerminalAdBannerTypeEdge = {
  __typename?: 'TerminalADBannerTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<TerminalAdBannerType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type TerminalAddedSubscription = {
  __typename?: 'TerminalAddedSubscription';
  terminal?: Maybe<TerminalType>;
};

export type TerminalDeletedSubscription = {
  __typename?: 'TerminalDeletedSubscription';
  terminal?: Maybe<TerminalType>;
};

export type TerminalListType = Node & {
  __typename?: 'TerminalListType';
  /** The ID of the object. */
  id: Scalars['ID'];
  version: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
  orgId: Scalars['Int'];
  uuid: Scalars['UUID'];
  url: Scalars['String'];
  address: Scalars['String'];
  addressForReport?: Maybe<Scalars['String']>;
  isStock: Scalars['Boolean'];
  isSalesPoint: Scalars['Boolean'];
  long?: Maybe<Scalars['Decimal']>;
  lat?: Maybe<Scalars['Decimal']>;
  alt?: Maybe<Scalars['String']>;
  status: TerminalStatus;
  /** Можно выбрать не больше одного дополнительного провайдера оплаты! */
  paymentProviders: PaymentProviderTypeConnection;
  /** Данный терминал работает в Демо-режиме */
  isDemoMode: Scalars['Boolean'];
  refrigeratorId: Scalars['String'];
  refrigeratorStatus?: Maybe<TerminalRefrigeratorStatus>;
  refrigeratorUrl: Scalars['String'];
  /** Время до которого должен быть обслужен терминал */
  serviceTime?: Maybe<TerminalServiceTime>;
  paymentproviderauthdataSet: PpAuthDataTypeConnection;
  /** Выбранные терминалы */
  banners: TerminalAdBannerTypeConnection;
  /** Логотипы для терминала */
  logos: TerminalLogoTypeConnection;
  /** Терминал, к которому применяется скидка (Если не указывать, скидка применится ко всем терминалам) */
  pricemodificationSet: PriceModificationTypeConnection;
  /** Терминалы, на которые не распространяется скидка */
  excludedTerminals: PriceModificationTypeConnection;
  shipmentSet: ShipmentTypeConnection;
  shipmentStock: ShipmentTypeConnection;
  itemSet: ItemTypeConnection;
  /** Логи терминала */
  terminallogSet: TerminalLogTypeConnection;
  /** Ассортимент терминала */
  assortmentSet: LeftoverTypeConnection;
  cartSet: CartTypeConnection;
  bannersScripts?: Maybe<Array<Maybe<AdvertisingBannerScriptType>>>;
  /** Выберите исключенные терминалы */
  bannersScriptsExcluded: AdvertisingBannerScriptTypeConnection;
  authData?: Maybe<PpAuthDataType>;
};


export type TerminalListTypePaymentProvidersArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
};


export type TerminalListTypePaymentproviderauthdataSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
};


export type TerminalListTypeBannersArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type TerminalListTypeLogosArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type TerminalListTypePricemodificationSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
};


export type TerminalListTypeExcludedTerminalsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
};


export type TerminalListTypeShipmentSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
};


export type TerminalListTypeShipmentStockArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
};


export type TerminalListTypeItemSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
};


export type TerminalListTypeTerminallogSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
};


export type TerminalListTypeAssortmentSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  product_Id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type TerminalListTypeCartSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  fromChatBot?: Maybe<Scalars['Boolean']>;
};


export type TerminalListTypeBannersScriptsExcludedArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type TerminalListTypeAuthDataArgs = {
  paymentProvider?: Maybe<Scalars['String']>;
};

export type TerminalListTypeConnection = {
  __typename?: 'TerminalListTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TerminalListTypeEdge>>;
};

/** A Relay edge containing a `TerminalListType` and its cursor. */
export type TerminalListTypeEdge = {
  __typename?: 'TerminalListTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<TerminalListType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type TerminalLogAddedSubscription = {
  __typename?: 'TerminalLogAddedSubscription';
  terminalLog?: Maybe<TerminalLogType>;
};

export type TerminalLogDeletedSubscription = {
  __typename?: 'TerminalLogDeletedSubscription';
  terminalLog?: Maybe<TerminalLogType>;
};

/** An enumeration. */
export enum TerminalLogPaymentProvider {
  /** Сбербанк */
  Sberbank = 'SBERBANK',
  /** Промсвязьбанк */
  Psbank = 'PSBANK',
  /** Tбанк */
  Tbank = 'TBANK',
  /** Фуд карты */
  Foodcard = 'FOODCARD',
  /** Яндекс ГО */
  Yandexgo = 'YANDEXGO',
  /** На ланч */
  Nalunch = 'NALUNCH',
  /** Яндекс Бейдж */
  Yandexbadge = 'YANDEXBADGE',
  /** ОбедРу */
  Obedru = 'OBEDRU'
}

/** Логи терминалов. */
export type TerminalLogType = Node & {
  __typename?: 'TerminalLogType';
  /** The ID of the object. */
  id: Scalars['ID'];
  /** идентификатор организации  */
  orgId: Scalars['Int'];
  /** Дата и время создания */
  createdAt: Scalars['DateTime'];
  /** Дата лога */
  date: Scalars['Date'];
  /** Логи терминала */
  terminal: TerminalListType;
  /** Корзина */
  cart?: Maybe<CartType>;
  /** Типа лога */
  type?: Maybe<Scalars['String']>;
  /** Сообщение */
  message: Scalars['JSONString'];
  paymentProvider?: Maybe<TerminalLogPaymentProvider>;
  /** Клиент */
  customer?: Maybe<CustomerType>;
};

export type TerminalLogTypeConnection = {
  __typename?: 'TerminalLogTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TerminalLogTypeEdge>>;
};

/** A Relay edge containing a `TerminalLogType` and its cursor. */
export type TerminalLogTypeEdge = {
  __typename?: 'TerminalLogTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<TerminalLogType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum TerminalLogTypes {
  PaymentSuccess = 'PAYMENT_SUCCESS',
  BarcodeFailed = 'BARCODE_FAILED',
  DataListenerRequest = 'DATA_LISTENER_REQUEST',
  TnInvoiceNumber = 'TN_INVOICE_NUMBER',
  PaymentFailed = 'PAYMENT_FAILED',
  AssortmentNotFound = 'ASSORTMENT_NOT_FOUND',
  TransactionSaveError = 'TRANSACTION_SAVE_ERROR',
  CancellationFailed = 'CANCELLATION_FAILED',
  OfdCheckNotRecieved = 'OFD_CHECK_NOT_RECIEVED',
  Sync_1CSuccess = 'SYNC_1C_SUCCESS',
  RefrigeratorOpen = 'REFRIGERATOR_OPEN',
  RefrigeratorStatus = 'REFRIGERATOR_STATUS',
  RefrigeratorError = 'REFRIGERATOR_ERROR',
  LoyaltyError = 'LOYALTY_ERROR',
  ActiveShipmentNotFound = 'ACTIVE_SHIPMENT_NOT_FOUND',
  InsufficientFunds = 'INSUFFICIENT_FUNDS',
  ReadScannerError = 'READ_SCANNER_ERROR',
  SaveMenuFailed = 'SAVE_MENU_FAILED'
}

export type TerminalLogUpdatedSubscription = {
  __typename?: 'TerminalLogUpdatedSubscription';
  terminalLog?: Maybe<TerminalLogType>;
};

/** Логотипы терминалов. */
export type TerminalLogoType = Node & {
  __typename?: 'TerminalLogoType';
  /** The ID of the object. */
  id: Scalars['ID'];
  version: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
  orgId: Scalars['Int'];
  title: Scalars['String'];
  /** Изображение лого для терминалов */
  image: Scalars['String'];
  /** Логотипы для терминала */
  terminals: TerminalListTypeConnection;
};


/** Логотипы терминалов. */
export type TerminalLogoTypeTerminalsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  status?: Maybe<Scalars['String']>;
};

export type TerminalLogoTypeConnection = {
  __typename?: 'TerminalLogoTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TerminalLogoTypeEdge>>;
};

/** A Relay edge containing a `TerminalLogoType` and its cursor. */
export type TerminalLogoTypeEdge = {
  __typename?: 'TerminalLogoTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<TerminalLogoType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum TerminalRefrigeratorStatus {
  /** Успешно выполнено */
  Ok = 'OK',
  /** Ошибка запроса */
  Error = 'ERROR'
}

/** An enumeration. */
export enum TerminalServiceTime {
  None = 'NONE',
  /** 9:00 */
  A_09_00_00 = 'A_09_00_00',
  /** 10:00 */
  A_10_00_00 = 'A_10_00_00',
  /** 11:00 */
  A_11_00_00 = 'A_11_00_00',
  /** 12:00 */
  A_12_00_00 = 'A_12_00_00',
  /** 13:00 */
  A_13_00_00 = 'A_13_00_00',
  /** 14:00 */
  A_14_00_00 = 'A_14_00_00'
}

/** An enumeration. */
export enum TerminalStatus {
  /** Активный */
  A_10 = 'A_10',
  /** Подготовлен */
  A_20 = 'A_20',
  /** Неактивный */
  A_30 = 'A_30'
}

/** Терминалы */
export type TerminalType = Node & {
  __typename?: 'TerminalType';
  /** The ID of the object. */
  id: Scalars['ID'];
  version: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<Scalars['String']>;
  isDeleted: Scalars['Boolean'];
  orgId: Scalars['Int'];
  uuid: Scalars['UUID'];
  url: Scalars['String'];
  address: Scalars['String'];
  addressForReport?: Maybe<Scalars['String']>;
  isStock: Scalars['Boolean'];
  isSalesPoint: Scalars['Boolean'];
  long?: Maybe<Scalars['Decimal']>;
  lat?: Maybe<Scalars['Decimal']>;
  alt?: Maybe<Scalars['String']>;
  status: TerminalStatus;
  /** Можно выбрать не больше одного дополнительного провайдера оплаты! */
  paymentProviders: PaymentProviderTypeConnection;
  /** Данный терминал работает в Демо-режиме */
  isDemoMode: Scalars['Boolean'];
  refrigeratorId: Scalars['String'];
  refrigeratorStatus?: Maybe<TerminalRefrigeratorStatus>;
  refrigeratorUrl: Scalars['String'];
  /** Время до которого должен быть обслужен терминал */
  serviceTime?: Maybe<TerminalServiceTime>;
  paymentproviderauthdataSet: PpAuthDataTypeConnection;
  /** Выбранные терминалы */
  banners: TerminalAdBannerTypeConnection;
  /** Логотипы для терминала */
  logos: TerminalLogoTypeConnection;
  /** Терминал, к которому применяется скидка (Если не указывать, скидка применится ко всем терминалам) */
  pricemodificationSet: PriceModificationTypeConnection;
  /** Терминалы, на которые не распространяется скидка */
  excludedTerminals: PriceModificationTypeConnection;
  shipmentSet: ShipmentTypeConnection;
  shipmentStock: ShipmentTypeConnection;
  itemSet: ItemTypeConnection;
  /** Логи терминала */
  terminallogSet: TerminalLogTypeConnection;
  /** Ассортимент терминала */
  assortmentSet: LeftoverTypeConnection;
  cartSet: CartTypeConnection;
  bannersScripts?: Maybe<Array<Maybe<AdvertisingBannerScriptType>>>;
  /** Выберите исключенные терминалы */
  bannersScriptsExcluded: AdvertisingBannerScriptTypeConnection;
  authData?: Maybe<PpAuthDataType>;
};


/** Терминалы */
export type TerminalTypePaymentProvidersArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
};


/** Терминалы */
export type TerminalTypePaymentproviderauthdataSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
};


/** Терминалы */
export type TerminalTypeBannersArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Терминалы */
export type TerminalTypeLogosArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Терминалы */
export type TerminalTypePricemodificationSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
};


/** Терминалы */
export type TerminalTypeExcludedTerminalsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Float']>;
};


/** Терминалы */
export type TerminalTypeShipmentSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
};


/** Терминалы */
export type TerminalTypeShipmentStockArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
};


/** Терминалы */
export type TerminalTypeItemSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
};


/** Терминалы */
export type TerminalTypeTerminallogSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
};


/** Терминалы */
export type TerminalTypeAssortmentSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
  product_Id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** Терминалы */
export type TerminalTypeCartSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  fromChatBot?: Maybe<Scalars['Boolean']>;
};


/** Терминалы */
export type TerminalTypeBannersScriptsExcludedArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Терминалы */
export type TerminalTypeAuthDataArgs = {
  paymentProvider?: Maybe<Scalars['String']>;
};

export type TerminalTypeConnection = {
  __typename?: 'TerminalTypeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TerminalTypeEdge>>;
};

/** A Relay edge containing a `TerminalType` and its cursor. */
export type TerminalTypeEdge = {
  __typename?: 'TerminalTypeEdge';
  /** The item at the end of the edge */
  node?: Maybe<TerminalType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type TerminalUpdatedSubscription = {
  __typename?: 'TerminalUpdatedSubscription';
  terminal?: Maybe<TerminalType>;
};



/**
 * Получение данных клиента в программе лояльности.
 * :param customer: None, если клиент не найден либо не зарегистрирован в программе лояльности
 */
export type UpdateCustomerLoyaltyMutation = {
  __typename?: 'UpdateCustomerLoyaltyMutation';
  ok: Scalars['Boolean'];
  errors: Array<Maybe<Scalars['String']>>;
  /** Идентификатор */
  id?: Maybe<Scalars['ID']>;
  customer?: Maybe<CustomerLoyaltyType>;
};

export type WeSubscriptions = {
  __typename?: 'WESubscriptions';
  priceModificationUpdated?: Maybe<PriceModificationUpdatedSubscription>;
  terminalAdded?: Maybe<TerminalAddedSubscription>;
  terminalUpdated?: Maybe<TerminalUpdatedSubscription>;
  terminalDeleted?: Maybe<TerminalDeletedSubscription>;
  cartPriceAdded?: Maybe<CartPriceAddedSubscription>;
  cartAdded?: Maybe<CartAddedSubscription>;
  cartUpdated?: Maybe<CartUpdatedSubscription>;
  cartDeleted?: Maybe<CartDeletedSubscription>;
  customerFeedbackAdded?: Maybe<CustomerFeedbackAddedSubscription>;
  customerFeedbackUpdated?: Maybe<CustomerFeedbackUpdatedSubscription>;
  customerFeedbackDeleted?: Maybe<CustomerFeedbackDeletedSubscription>;
  customerAdded?: Maybe<CustomerAddedSubscription>;
  customerUpdated?: Maybe<CustomerUpdatedSubscription>;
  customerDeleted?: Maybe<CustomerDeletedSubscription>;
  productSubcategoryAdded?: Maybe<ProductSubCategoryAddedSubscription>;
  productSubcategoryUpdated?: Maybe<ProductSubCategoryUpdatedSubscription>;
  productSubcategoryDeleted?: Maybe<ProductSubCategoryDeletedSubscription>;
  productCategoryAdded?: Maybe<ProductCategoryAddedSubscription>;
  productCategoryUpdated?: Maybe<ProductCategoryUpdatedSubscription>;
  productCategoryDeleted?: Maybe<ProductCategoryDeletedSubscription>;
  leftoverAdded?: Maybe<LeftoverAddedSubscription>;
  leftoverUpdated?: Maybe<LeftoverUpdatedSubscription>;
  leftoverDeleted?: Maybe<LeftoverDeletedSubscription>;
  terminalLogAdded?: Maybe<TerminalLogAddedSubscription>;
  terminalLogUpdated?: Maybe<TerminalLogUpdatedSubscription>;
  terminalLogDeleted?: Maybe<TerminalLogDeletedSubscription>;
};


export type WeSubscriptionsPriceModificationUpdatedArgs = {
  macAddress: Scalars['String'];
};


export type WeSubscriptionsTerminalAddedArgs = {
  macAddress: Scalars['String'];
};


export type WeSubscriptionsTerminalUpdatedArgs = {
  macAddress: Scalars['String'];
};


export type WeSubscriptionsTerminalDeletedArgs = {
  macAddress: Scalars['String'];
};


export type WeSubscriptionsCartPriceAddedArgs = {
  macAddress: Scalars['String'];
};


export type WeSubscriptionsCartAddedArgs = {
  macAddress: Scalars['String'];
};


export type WeSubscriptionsCartUpdatedArgs = {
  macAddress: Scalars['String'];
};


export type WeSubscriptionsCartDeletedArgs = {
  macAddress: Scalars['String'];
};


export type WeSubscriptionsProductSubcategoryAddedArgs = {
  macAddress: Scalars['String'];
};


export type WeSubscriptionsProductSubcategoryUpdatedArgs = {
  macAddress: Scalars['String'];
};


export type WeSubscriptionsProductSubcategoryDeletedArgs = {
  macAddress: Scalars['String'];
};


export type WeSubscriptionsProductCategoryAddedArgs = {
  macAddress: Scalars['String'];
};


export type WeSubscriptionsProductCategoryUpdatedArgs = {
  macAddress: Scalars['String'];
};


export type WeSubscriptionsProductCategoryDeletedArgs = {
  macAddress: Scalars['String'];
};


export type WeSubscriptionsLeftoverAddedArgs = {
  macAddress: Scalars['String'];
};


export type WeSubscriptionsLeftoverUpdatedArgs = {
  macAddress: Scalars['String'];
};


export type WeSubscriptionsLeftoverDeletedArgs = {
  macAddress: Scalars['String'];
};


export type WeSubscriptionsTerminalLogAddedArgs = {
  macAddress: Scalars['String'];
};


export type WeSubscriptionsTerminalLogUpdatedArgs = {
  macAddress: Scalars['String'];
};


export type WeSubscriptionsTerminalLogDeletedArgs = {
  macAddress: Scalars['String'];
};
